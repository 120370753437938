export async function getAllUsers() {

    try {
        const response = await fetch('https://timelog-api.xant.org/');
        return await response.json();
    } catch (error) {
        return [];
    }

}
